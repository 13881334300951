/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "So... PCMR. Okay, cool, PC Master Race, all things PC, I fell for it. I do like technology but I like compatability more."), "\n", React.createElement(_components.p, null, "\"What does that mean Jeff?!\" Great question!"), "\n", React.createElement(_components.p, null, "I bounce around between systems a lot and I don't really have the time and energy to keep up on what is compatible with each system."), "\n", React.createElement(_components.p, null, "These headsets are fantastic, hands down they are one of the best headsets I've ever used... except when I want to play Xbox."), "\n", React.createElement(_components.p, null, "They aren't compatible... And I get it, with enough research I would have found that out but I don't want to spend the time on that..."), "\n", React.createElement(_components.p, null, "And I don't think you should either! So, good news, they are fantastic with PC and I assume PS5 based on the reviews as well, but if you're an Xbox player, you're going to want to look for something compatible with Xbox. They have a proprietary software that prevents any ordinary USB dongle from working."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
